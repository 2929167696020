import React from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import Notiflix from "notiflix";

import { loginRequest } from "../../authConfig";
import { useLoginMutation } from "../../redux/services/authApi";

export const MicrosoftOAuth: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [login] = useLoginMutation();

  const getRefreshToken = () => {
    let keys = [];
    for (let i = 0; i < sessionStorage.length; i++) {
      keys.push(sessionStorage.key(i));
    }

    const ssd = keys.filter((key) => key?.includes("refreshtoken"));

    for (let i = 0; i < ssd.length; i++) {
      const data = sessionStorage.getItem(`${ssd[i]}`);
      if (data) {
        return JSON.parse(data).secret;
      }
    }
  };

  const handleLogin = async () => {
    await instance
      .loginPopup(loginRequest)
      .then(async (response) => {
        const microsoftRefreshToken = await getRefreshToken();

        const { accessToken, account, expiresOn } = response;
        const date = expiresOn || new Date();

        try {
          const { jwtToken } = await login({
            microsoftAccessToken: accessToken,
            microsoftUserId: account.localAccountId,
            microsoftRefreshToken,
            microsoftExpiresAt: date,
          }).unwrap();

          localStorage.setItem("jwt", jwtToken);
          Notiflix.Notify.success("Login successful");
          navigate("/calendar");
        } catch (error) {
          console.error("Error sending MS credentials to back-end:", error);
        }
      })
      .catch((error) => {
        console.error("Login error:", error);
        Notiflix.Notify.failure("Login error");
      });
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
      <Box mb={3}>
        <Typography variant="h4" gutterBottom>
          For calendar events login with Microsoft
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogin}
        size="large"
      >
        Sign in with Microsoft
      </Button>
    </Container>
  );
};
