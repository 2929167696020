import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TokenManager } from "../TokenManager";

export interface CalendarEvent {
  id: string;
  subject: string;
  bodyPreview?: string;
  start: {
    dateTime: string;
  };
  end: {
    dateTime: string;
  };
}

interface GetEventsParams {
  startDate: string;
  endDate: string;
}

export const calendarApi = createApi({
  reducerPath: "calendarApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers, { extra }) => {
      const { tokenManager } = extra as { tokenManager: TokenManager };
      const token = await tokenManager.checkAndUpdateToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEvents: builder.query<CalendarEvent[], GetEventsParams>({
      query: ({ startDate, endDate }) => ({
        url: "calendar",
        method: "GET",
        params: { startDate, endDate },
      }),
    }),
  }),
});

export const { useGetEventsQuery } = calendarApi;
