import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface AuthResponse {
  jwtToken: string;
}

interface LoginPayload {
  microsoftAccessToken: string;
  microsoftUserId: string;
  microsoftRefreshToken: string;
  microsoftExpiresAt: Date;
}

interface RefreshPayload {
  microsoftAccessToken: string;
  microsoftUserId: string;
  microsoftRefreshToken: string;
  microsoftExpiresAt: Date;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, LoginPayload>({
      query: (credentials) => ({
        url: "/auth/microsoft",
        method: "POST",
        body: credentials,
      }),
    }),
    refreshAccessToken: builder.mutation<AuthResponse, RefreshPayload>({
      query: (credentials) => ({
        url: "/auth/microsoft",
        method: "PATCH",
        body: credentials,
      }),
    }),
  }),
});

export const { useLoginMutation, useRefreshAccessTokenMutation } = authApi;
