import React, { useState, useEffect, useRef, useCallback } from "react";
import dayjs from "dayjs";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { IoEarth } from "react-icons/io5";

import "./TimezoneSelector.css";
import { timezones } from "../../../utils/helpers/timezones";
import { useCurrentTime } from "./useCurrentTime";

interface TimezoneSelectorProps {
  selectedTimezone: string;
  setSelectedTimezone: (tz: string) => void;
  is24HourFormat: boolean;
  setIs24HourFormat: (is24Hour: boolean) => void;
}

const isValidTimezone = (tz: string): boolean => {
  try {
    dayjs.tz(dayjs(), tz);
    return true;
  } catch {
    return false;
  }
};

const CurrentTime = React.memo(
  ({
    timezone,
    is24HourFormat,
  }: {
    timezone: string;
    is24HourFormat: boolean;
  }) => {
    const currentTime = useCurrentTime();
    return (
      <span>
        {currentTime.tz(timezone).format(is24HourFormat ? "HH:mm" : "hh:mm A")}
      </span>
    );
  }
);

const TimezoneSelector: React.FC<TimezoneSelectorProps> = ({
  selectedTimezone,
  setSelectedTimezone,
  is24HourFormat,
  setIs24HourFormat,
}) => {
  const [isTimezoneSelectorOpen, setIsTimezoneSelectorOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const selectedOptionRef = useRef<HTMLButtonElement>(null);
  const isFirefox = navigator.userAgent.includes("Firefox");

  const handleTimezoneChange = useCallback(
    (tz: string) => {
      if (isValidTimezone(tz)) {
        setSelectedTimezone(tz);
        setIsTimezoneSelectorOpen(false);
      } else {
        console.error(`Invalid timezone: ${tz}`);
      }
    },
    [setSelectedTimezone]
  );

  const filteredTimezones = React.useMemo(
    () =>
      timezones(isFirefox)
        .map((region) => {
          const filteredZones = region.zones.filter((zone) =>
            zone.label.toLowerCase().includes(searchTerm.toLowerCase())
          );

          if (filteredZones.length > 0) {
            return {
              region: region.region,
              zones: filteredZones,
            };
          }

          return null;
        })
        .filter(Boolean),
    [searchTerm, isFirefox]
  );

  const selectedTzLabel = React.useMemo(
    () =>
      timezones(isFirefox)
        .find((reg) => reg.zones.find((tz) => tz.value === selectedTimezone))
        ?.zones.find((tz) => tz.value === selectedTimezone)?.label || "Unknown",
    [selectedTimezone, isFirefox]
  );

  useEffect(() => {
    if (isTimezoneSelectorOpen && selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isTimezoneSelectorOpen]);

  return (
    <div className="calendar__timezone">
      <h3 className="calendar_timezone-title">Time zone</h3>
      <div className="calendar__timezone-btn-container">
        <button
          onClick={() => setIsTimezoneSelectorOpen(!isTimezoneSelectorOpen)}
          className="calendar__timezone-btn"
          aria-expanded={isTimezoneSelectorOpen}
          aria-haspopup="listbox"
        >
          <IoEarth size={16} aria-hidden="true" />
          <span className="calendar__timezone-btn-text">
            {selectedTzLabel} (
            <CurrentTime
              timezone={selectedTimezone}
              is24HourFormat={is24HourFormat}
            />
            )
          </span>
          {isTimezoneSelectorOpen ? (
            <IoMdArrowDropup size={15} aria-hidden="true" />
          ) : (
            <IoMdArrowDropdown size={15} aria-hidden="true" />
          )}
        </button>
      </div>
      {isTimezoneSelectorOpen && (
        <div className="calendar__timezone-selector" role="listbox">
          <div className="calendar__timezone-selector-head">
            <div className="calendar__timezone-search">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                aria-label="Search timezones"
              />
            </div>
            <div className="calendar__timezone-format">
              <span id="time-format-label">TIME ZONE</span>
              <div
                className="calendar__timezone-toggle"
                role="group"
                aria-labelledby="time-format-label"
              >
                <p id="am-pm-label">am/pm</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={is24HourFormat}
                    onChange={() => setIs24HourFormat(!is24HourFormat)}
                    aria-labelledby="am-pm-label hour24-label"
                  />
                  <span className="slider round"></span>
                </label>
                <p id="hour24-label">24h</p>
              </div>
            </div>
          </div>
          <div className="calendar__timezone-selector-list-wrapper">
            <div className="calendar__timezone-selector-list">
              {filteredTimezones?.map((region) => (
                <div key={region?.region}>
                  <h4 className="calendar__timezone-selector-list-region">
                    {region?.region}
                  </h4>
                  {region?.zones.map(({ label, value }) => (
                    <button
                      key={value}
                      ref={
                        value === selectedTimezone ? selectedOptionRef : null
                      }
                      onClick={() => handleTimezoneChange(value)}
                      className={`calendar__timezone-option ${
                        selectedTimezone === value
                          ? "calendar__timezone-option--selected"
                          : ""
                      }`}
                      role="option"
                      aria-selected={selectedTimezone === value}
                    >
                      <span>{label}</span>
                      <CurrentTime
                        timezone={value}
                        is24HourFormat={is24HourFormat}
                      />
                    </button>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(TimezoneSelector);
