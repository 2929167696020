import React, { useState, useEffect } from "react";

import { MicrosoftOAuth } from "../../components/MicrosoftOAuth/MicrosoftOAuth";

const randomRGB = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r},${g},${b})`;
};

const generatePositions = (numFigures: number) => {
  const positions: { top: string; left: string }[] = [];
  while (positions.length < numFigures) {
    const newPosition = {
      top: `${Math.random() * 80}%`,
      left: `${Math.random() * 80}%`,
    };

    const isFarEnough = positions.every(
      (pos) =>
        Math.abs(parseFloat(pos.top) - parseFloat(newPosition.top)) > 10 &&
        Math.abs(parseFloat(pos.left) - parseFloat(newPosition.left)) > 10
    );

    if (isFarEnough) {
      positions.push(newPosition);
    }
  }
  return positions;
};

const AuthPage: React.FC = () => {
  const [positions, setPositions] = useState<{ top: string; left: string }[]>(
    []
  );

  useEffect(() => {
    const generatedPositions = generatePositions(5);
    setPositions(generatedPositions);
  }, []);

  return (
    <div style={styles.authPage}>
      <div style={styles.abstractSection}>
        {positions.map((position, index) => (
          <div
            key={index}
            style={{
              ...styles.abstractFigure,
              ...position,
              backgroundColor: randomRGB(),
              borderRadius: index % 2 === 0 ? "50%" : "20%",
              transform: index % 3 === 0 ? "rotate(45deg)" : "",
            }}
          ></div>
        ))}
      </div>

      <div style={styles.loginSection}>
        <MicrosoftOAuth />
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  authPage: {
    display: "flex",
    height: "100vh",
  },
  abstractSection: {
    flex: 1,
    background: "#f0f0f0",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  abstractFigure: {
    position: "absolute",
    width: "150px",
    height: "150px",
  },
  loginSection: {
    flex: 1,
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    padding: "30px",
    borderRadius: "10px",
  },
};

export default AuthPage;
