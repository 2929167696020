import { patchMSToken } from "../utils/api/auth";
import { refreshAccessToken } from "../utils/helpers/refresh";

export class TokenManager {
  private token: string | null = null;

  async checkAndUpdateToken(): Promise<string | null> {
    const jwt = localStorage.getItem("jwt");

    if (!jwt) {
      localStorage.removeItem("jwt");
      this.token = null;
      return null;
    }

    this.token = jwt;

    try {
      const decodedToken = JSON.parse(atob(this.token.split(".")[1]));
      if (new Date() > new Date(decodedToken.expiresAt)) {
        const accData = await refreshAccessToken(
          decodedToken.token,
          decodedToken.sub
        );

        if (accData) {
          const newJwt = await patchMSToken(accData, this.token);
          localStorage.setItem("jwt", newJwt);
          this.token = newJwt;
          return newJwt;
        } else {
          localStorage.removeItem("jwt");
          this.token = null;
          return null;
        }
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      localStorage.removeItem("jwt");
      this.token = null;
      return null;
    }

    return this.token;
  }
}
