import { useState, useEffect } from "react";
import dayjs from "dayjs";

export const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    const initialUpdate = setTimeout(() => {
      setCurrentTime(dayjs());
    }, 1000 - dayjs().millisecond());

    const timer = setInterval(() => {
      setCurrentTime(dayjs());
    }, 1000);

    return () => {
      clearTimeout(initialUpdate);
      clearInterval(timer);
    };
  }, []);

  return currentTime;
};
