import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import "./EventList.css";

interface EventListProps {
  selectedDay: dayjs.Dayjs | null;
  events: any[];
  selectedTimezone: string;
  is24HourFormat: boolean;
  currentDate: dayjs.Dayjs;
}

export default function EventList({
  selectedDay,
  events,
  selectedTimezone,
  is24HourFormat,
  currentDate,
}: EventListProps) {
  const [selectedDayEvents, setSelectedDayEvents] = useState<any[]>([]);

  useEffect(() => {
    if (selectedDay) {
      const isSelectedDayInCurrentMonth = selectedDay.isSame(
        currentDate,
        "month"
      );

      if (isSelectedDayInCurrentMonth) {
        const filteredEvents = events.filter((event: any) => {
          const eventStart = dayjs(`${event.start.dateTime}Z`).tz(
            selectedTimezone
          );
          return eventStart.isSame(selectedDay, "day");
        });
        setSelectedDayEvents(filteredEvents);
      } else {
        setSelectedDayEvents((prevEvents) => prevEvents);
      }
    } else {
      setSelectedDayEvents([]);
    }
  }, [selectedDay, events, selectedTimezone, currentDate]);

  if (!selectedDay) {
    return null;
  }

  const timeSlots = selectedDayEvents
    .map((event) => ({
      time: dayjs(`${event.start.dateTime}Z`)
        .tz(selectedTimezone)
        .format(is24HourFormat ? "HH:mm" : "hh:mm A"),
      dateTime: `${event.start.dateTime}Z`,
      id: event.id,
    }))
    .sort((a, b) => a.dateTime.localeCompare(b.dateTime))
    .map((slot) => ({ time: slot.time, id: slot.id }));

  const eventsTitle = selectedDay.format("dddd, MMMM D");

  return (
    <div className="calendar__events">
      <h3 className="calendar__events-title">{eventsTitle}</h3>
      {selectedDayEvents.length > 0 ? (
        <div className="calendar__time-slots-wrapper">
          <div className="calendar__time-slots">
            {timeSlots.map(({ time, id }) => (
              <button key={id} className="calendar__time-slot">
                {time}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <p className="calendar__no-events">No events scheduled for this day.</p>
      )}
    </div>
  );
}
