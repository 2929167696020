import axios from "axios";

export const patchMSToken = async (
  accData: any,
  token: string
): Promise<string> => {
  return (
    await axios.patch(
      `${process.env.REACT_APP_API_URL}/auth/microsoft`,
      {
        microsoftAccessToken: accData.accessToken,
        microsoftUserId: accData.userId,
        microsoftRefreshToken: accData.refreshToken,
        microsoftExpiresAt: accData.expiresIn,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).data.jwtToken;
};

export const postMSToken = async ({
  microsoftAccessToken,
  microsoftUserId,
  microsoftRefreshToken,
  microsoftExpiresAt,
}: {
  microsoftAccessToken: string;
  microsoftUserId: string;
  microsoftRefreshToken: string;
  microsoftExpiresAt: Date;
}) => {
  return (
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/microsoft`, {
      microsoftAccessToken,
      microsoftUserId,
      microsoftRefreshToken,
      microsoftExpiresAt,
    })
  ).data.jwtToken;
};
