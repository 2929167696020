import { configureStore } from "@reduxjs/toolkit";
import { calendarApi } from "./services/calendarApi";
import { authApi } from "./services/authApi";
import { TokenManager } from "./TokenManager";

const tokenManager = new TokenManager();

export const store = configureStore({
  reducer: {
    [calendarApi.reducerPath]: calendarApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { tokenManager },
      },
    }).concat(calendarApi.middleware, authApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
