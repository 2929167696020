import React from "react";
import dayjs from "dayjs";

import "./CalendarGrid.css";

const daysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

interface CalendarGridProps {
  currentDate: dayjs.Dayjs;
  selectedDay: dayjs.Dayjs | null;
  selectedTimezone: string;
  getUniqueEventDays: () => dayjs.Dayjs[];
  onDayClick: (day: dayjs.Dayjs) => void;
}

export default function CalendarGrid({
  currentDate,
  selectedDay,
  selectedTimezone,
  getUniqueEventDays,
  onDayClick,
}: CalendarGridProps) {
  const renderCalendarDays = () => {
    const daysInMonth = currentDate.daysInMonth();
    const firstDayOfMonth = currentDate.startOf("month").day();
    const days = [];
    const uniqueEventDays = getUniqueEventDays();
    const today = dayjs().tz(selectedTimezone).startOf("day");

    for (
      let i = 0;
      i < (firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1);
      i++
    ) {
      days.push(
        <div
          key={`empty-${i}`}
          className="calendar__day calendar__day--empty"
        />
      );
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const day = currentDate.date(i);
      const isSelected = selectedDay?.isSame(day, "day");
      const isEventDay = uniqueEventDays.some((d) => d.isSame(day, "day"));
      const isToday = day.isSame(today, "day");
      const isPastDay = day.isBefore(today, "day");

      days.push(
        <button
          key={i}
          onClick={() => onDayClick(day)}
          className={`calendar__day ${
            isSelected ? "calendar__day--selected" : ""
          } ${isEventDay ? "calendar__day--event" : ""} ${
            isToday ? "calendar__day--today" : ""
          }`}
          disabled={!isEventDay || isPastDay}
        >
          {i}
          {isToday && (
            <span
              className={`calendar__day-dot${
                isSelected ? "--selected" : isEventDay ? "" : "--no-event"
              }`}
            ></span>
          )}
        </button>
      );
    }

    return days;
  };

  return (
    <>
      <div className="calendar__weekdays">
        {daysOfWeek.map((day) => (
          <div key={day} className="calendar__weekday">
            {day}
          </div>
        ))}
      </div>
      <div className="calendar__days">{renderCalendarDays()}</div>
    </>
  );
}
