import axios from "axios";

const clientId = process.env.REACT_APP_CLIENT_ID;
const tenantId = "common";
const scopes = ["user.read"].join(" ");

export const refreshAccessToken = async (
  refreshToken: string,
  userId: string
) => {
  const tokenEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;

  const tokenData = {
    client_id: clientId || "",
    scope: scopes,
    refresh_token: refreshToken || "",
    grant_type: "refresh_token",
  };

  try {
    const response = await axios.post(
      tokenEndpoint,
      new URLSearchParams(tokenData),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    const newAccessToken = response.data.access_token;
    const newRefreshToken = response.data.refresh_token;
    const date = new Date();
    date.setHours(date.getHours() + 1);
    const expiresIn = date;

    return {
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
      expiresIn: expiresIn,
      userId,
    };
  } catch (error: any) {
    console.error(
      "Failed to refresh token: ",
      error.response ? error.response.data : error.message
    );
    return null;
  }
};
