export const timezones = (isFirefox: boolean) => {
  return [
    {
      region: "UTC",
      zones: [{ label: "UTC", value: "UTC" }],
    },
    {
      region: "Africa",
      zones: [
        { label: "West Africa Time", value: "Africa/Lagos" },
        { label: "Central Africa Time", value: "Africa/Harare" },
        { label: "East Africa Time", value: "Africa/Nairobi" },
        { label: "South Africa Standard Time", value: "Africa/Johannesburg" },
        { label: "Egypt Standard Time", value: "Africa/Cairo" },
        { label: "Morocco Time", value: "Africa/Casablanca" },
      ],
    },
    {
      region: "America",
      zones: [
        { label: "Eastern Time (US & Canada)", value: "America/New_York" },
        { label: "Central Time (US & Canada)", value: "America/Chicago" },
        { label: "Mountain Time (US & Canada)", value: "America/Denver" },
        { label: "Pacific Time (US & Canada)", value: "America/Los_Angeles" },
        { label: "Atlantic Time (Canada)", value: "America/Halifax" },
        { label: "Newfoundland Time", value: "America/St_Johns" },
        { label: "Argentina Time", value: "America/Argentina/Buenos_Aires" },
        { label: "Brasilia Time", value: "America/Sao_Paulo" },
        { label: "Chile Standard Time", value: "America/Santiago" },
        { label: "Peru Time", value: "America/Lima" },
        { label: "Mexico City Time", value: "America/Mexico_City" },
        { label: "Cuba Standard Time", value: "America/Havana" },
        { label: "Venezuela Time", value: "America/Caracas" },
        { label: "Colombia Time", value: "America/Bogota" },
        { label: "Paraguay Time", value: "America/Asuncion" },
      ],
    },
    {
      region: "Atlantic",
      zones: [
        { label: "Azores Time", value: "Atlantic/Azores" },
        { label: "Cape Verde Time", value: "Atlantic/Cape_Verde" },
        { label: "Greenland Time", value: "America/Godthab" },
        { label: "South Georgia Time", value: "Atlantic/South_Georgia" },
        { label: "Reykjavik Time", value: "Atlantic/Reykjavik" },
        { label: "St. Helena Time", value: "Atlantic/St_Helena" },
      ],
    },
    {
      region: "Asia",
      zones: [
        { label: "India Standard Time", value: "Asia/Kolkata" },
        { label: "Japan Standard Time", value: "Asia/Tokyo" },
        { label: "China Standard Time", value: "Asia/Shanghai" },
        { label: "Singapore Time", value: "Asia/Singapore" },
        { label: "Hong Kong Time", value: "Asia/Hong_Kong" },
        { label: "Korea Standard Time", value: "Asia/Seoul" },
        { label: "Bangladesh Standard Time", value: "Asia/Dhaka" },
        { label: "Indochina Time", value: "Asia/Bangkok" },
        { label: "Philippine Time", value: "Asia/Manila" },
        { label: "Pakistan Standard Time", value: "Asia/Karachi" },
        { label: "Nepal Time", value: "Asia/Kathmandu" },
        { label: "Israel Standard Time", value: "Asia/Jerusalem" },
        { label: "Gulf Standard Time", value: "Asia/Dubai" },
      ],
    },
    {
      region: "Australia",
      zones: [
        {
          label: "Australian Eastern Time (Sydney)",
          value: "Australia/Sydney",
        },
        {
          label: "Australian Central Time (Adelaide)",
          value: "Australia/Adelaide",
        },
        { label: "Australian Western Time (Perth)", value: "Australia/Perth" },
        { label: "Australian Central Western Time", value: "Australia/Eucla" },
        { label: "Lord Howe Time", value: "Australia/Lord_Howe" },
        { label: "Norfolk Island Time", value: "Pacific/Norfolk" },
      ],
    },
    {
      region: "Europe",
      zones: [
        { label: "Central European Time", value: "Europe/Berlin" },
        {
          label: "Eastern European Time",
          value: isFirefox ? "Europe/Kyiv" : "Europe/Kiev",
        },
        { label: "Western European Time", value: "Europe/Lisbon" },
        { label: "Greenwich Mean Time", value: "Europe/London" },
        { label: "Moscow Standard Time", value: "Europe/Moscow" },
        { label: "Paris Time", value: "Europe/Paris" },
        { label: "Rome Time", value: "Europe/Rome" },
        { label: "Madrid Time", value: "Europe/Madrid" },
        { label: "Warsaw Time", value: "Europe/Warsaw" },
        { label: "Istanbul Time", value: "Europe/Istanbul" },
        { label: "Athens Time", value: "Europe/Athens" },
        { label: "Bucharest Time", value: "Europe/Bucharest" },
        { label: "Helsinki Time", value: "Europe/Helsinki" },
      ],
    },
    {
      region: "Pacific",
      zones: [
        { label: "New Zealand Time", value: "Pacific/Auckland" },
        { label: "Fiji Time", value: "Pacific/Fiji" },
        { label: "Tahiti Time", value: "Pacific/Tahiti" },
        { label: "Hawaii-Aleutian Standard Time", value: "Pacific/Honolulu" },
        { label: "Chatham Island Time", value: "Pacific/Chatham" },
        { label: "Pitcairn Time", value: "Pacific/Pitcairn" },
        { label: "Easter Island Time", value: "Pacific/Easter" },
      ],
    },
    {
      region: "Antarctica",
      zones: [
        { label: "Antarctica Palmer Time", value: "Antarctica/Palmer" },
        { label: "Antarctica McMurdo Time", value: "Antarctica/McMurdo" },
        { label: "Antarctica Rothera Time", value: "Antarctica/Rothera" },
        { label: "Antarctica Vostok Time", value: "Antarctica/Vostok" },
      ],
    },
  ];
};
