import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import dayjs from "dayjs";

import "./CalendarHeader.css";

interface CalendarHeaderProps {
  currentDate: dayjs.Dayjs;
  onPreviousMonth: () => void;
  onNextMonth: () => void;
}

export default function CalendarHeader({
  currentDate,
  onPreviousMonth,
  onNextMonth,
}: CalendarHeaderProps) {
  const isPreviousMonthDisabled = currentDate.isSame(dayjs(), "month");

  return (
    <div className="calendar__nav">
      <button
        onClick={onPreviousMonth}
        className={`calendar__nav-btn ${
          isPreviousMonthDisabled ? "calendar__nav-btn--disabled" : ""
        }`}
        disabled={isPreviousMonthDisabled}
      >
        <ChevronLeft size={20} />
      </button>
      <h2 className="calendar__month">{currentDate.format("MMMM YYYY")}</h2>
      <button onClick={onNextMonth} className="calendar__nav-btn">
        <ChevronRight size={20} />
      </button>
    </div>
  );
}
